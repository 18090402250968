import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Colors from './Config/Colors';
import Participant from './Components/Participant';
import './App.css';
import testPlayers from './Util/testPlayers';

const MainView = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
`;

const InitiativeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

const Buttons = styled.div`
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
`;

const LeftSideBar = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  minmax(200px, 1fr);
`;

const RightSideBar = styled.div`
  display: grid;
  justify-content: start;
  align-items: start;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(auto-fill, 64px);
  minmax(200px, 1fr);
`;

const AddCharacter = styled.button`
  color: ${Colors.red};
  width: 100px;
`;

const AdvanceRound = styled.button`
  color: ${Colors.blue};
  background-color: ${(props) => (props.disabled ? Colors.gray : 'white')};
  width: 100px;
`;

const StartEncouner = styled.button`
  color: ${Colors.green};
  width: 100px;
`;

const DeleteAllCharacters = styled.button`
  color: ${Colors.red};
  justify-self: center;
  width: 100px;
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      round: 0,
      encounterStarted: false,
      players: testPlayers,
    };
    this.advancedRound = this.advancedRound.bind(this);
    this.deleteAll = this.deleteAll.bind(this);
    this.removeCondition = this.removeCondition.bind(this);
    this.addCondition = this.addCondition.bind(this);
  }

  advancedRound() {
    const { players } = this.state;
    let updatedPlayers = players;
    let { round } = this.state;
    players.push(players.shift());
    if (players[0].startPlayer) {
      round += 1;
      updatedPlayers = players.map((player) => {
        const updatedPlayer = player;
        const { conditions } = player;
        let updatedConditions = conditions.map((condition) => {
          const updatedCondition = condition;
          updatedCondition.conditionDuration -= 1;
          return updatedCondition;
        });
        updatedConditions = conditions.filter(
          (condition) => condition.conditionDuration > 0,
        );
        updatedPlayer.conditions = updatedConditions;
        return updatedPlayer;
      });
    }
    this.setState({ players: updatedPlayers, round });
  }

  startEncounter() {
    this.setState({ round: 1, encounterStarted: true });
  }

  deleteAll() {
    const response = window.confirm('Delete all characters?');
    if (response) {
      this.setState({ players: [] });
    }
  }

  removeCondition(playerNumber, conditionNumber) {
    const { players } = this.state;
    const playerConditions = players[playerNumber].conditions;
    _.remove(
      playerConditions,
      (condition) => condition.conditionNumber === conditionNumber,
    );
    players[playerNumber].conditions = playerConditions;
    this.setState({ players });
  }

  addCondition(playerNumber, conditionNumber, duration) {
    const { players } = this.state;
    const playerConditions = players[playerNumber].conditions;
    playerConditions.push({
      conditionNumber,
      conditionDuration: duration,
    });
    players[playerNumber].conditions = playerConditions;
    this.setState({ players });
  }

  render() {
    const { players, round, encounterStarted } = this.state;
    return (
      <MainView className="App">
        <LeftSideBar>
          <p>Left Side Bar</p>
        </LeftSideBar>
        <InitiativeSection>
          <p>Init Tracker</p>
          <p>{`Round ${round}`}</p>
          <Buttons>
            <AddCharacter>Add Character</AddCharacter>
            <AdvanceRound
              onClick={() => this.advancedRound()}
              disabled={!encounterStarted}
            >
              Advance Round
            </AdvanceRound>
            <StartEncouner onClick={() => this.startEncounter()}>
              Start Encounter
            </StartEncouner>
          </Buttons>
          {players.map((player, index) => (
            <Participant
              removeCondition={this.removeCondition}
              addCondition={this.addCondition}
              playerNumber={index}
              info={player}
              key={player.name}
            />
          ))}
        </InitiativeSection>
        <RightSideBar>
          <p>Settings</p>
          <DeleteAllCharacters onClick={() => this.deleteAll()}>
            Delete all Characters
          </DeleteAllCharacters>
        </RightSideBar>
      </MainView>
    );
  }
}

export default App;
