const testPlayers = [
  {
    name: 'Warrior McDude',
    startPlayer: true,
    classType: 'Warrior',
    initiative: 14,
    hitPoints: 8,
    conditions: [
      {
        conditionNumber: 0,
        conditionDuration: 1,
      },
      {
        conditionNumber: 1,
        conditionDuration: 3,
      },
    ],
  },
  {
    name: 'P-Funk',
    startPlayer: false,
    classType: 'Scrivener',
    initiative: 14,
    hitPoints: 12,
    conditions: [
      {
        conditionNumber: 2,
        conditionDuration: 6,
      },
      {
        conditionNumber: 3,
        conditionDuration: 7,
      },
    ],
  },
  {
    name: 'Clarisse',
    startPlayer: false,
    classType: 'Cleric',
    initiative: 17,
    hitPoints: 6,
    conditions: [
      {
        conditionNumber: 4,
        conditionDuration: 1,
      },
      {
        conditionNumber: 5,
        conditionDuration: 2,
      },
    ],
  },
  {
    name: 'Thundro',
    startPlayer: false,
    classType: 'Thief',
    initiative: 19,
    hitPoints: 4,
    conditions: [
      {
        conditionNumber: 6,
        conditionDuration: 1,
      },
      {
        conditionNumber: 7,
        conditionDuration: 2,
      },
      {
        conditionNumber: 8,
        conditionDuration: 1,
      },
      {
        conditionNumber: 9,
        conditionDuration: 2,
      },
      {
        conditionNumber: 10,
        conditionDuration: 3,
      },
      {
        conditionNumber: 11,
        conditionDuration: 4,
      },
      {
        conditionNumber: 12,
        conditionDuration: 1,
      },
      {
        conditionNumber: 13,
        conditionDuration: 2,
      },
      {
        conditionNumber: 14,
        conditionDuration: 1,
      },
      {
        conditionNumber: 15,
        conditionDuration: 2,
      },
    ],
  },
];

export default testPlayers;
