import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Colors from '../Config/Colors';
import Conditions5E from '../Config/Conditions';
import Condition from './Condition';

const ParticipantDiv = styled.div`
  color: ${Colors.black};
  font-size: 16px;
  display: grid;
  grid-template-columns: 50px 1fr;
  min-height: 80px;
`;

const InitiativeDiv = styled.div`
  padding-top: 5px;
  display: flex;
  flexdirection: column;
  justify-content: center;
  background-color: ${Colors.init};
`;

// const DataDiv = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   flex: 1;
// `;

const InfoDiv = styled.div`
  padding-left: 4px;
  padding-top: 5px;
  display: flex;
  flexdirection: row;
  flex: 1;
`;

const ConditionsDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const AddConditionButton = styled.button`
  background-color: transparent;
  border: 0px;
  color: blue;
  display: grid;
  padding: 0 4px;
  justify-self: start;
`;

function Participant(props) {
  const { playerNumber, addCondition, removeCondition, info } = props;
  const { name, initiative, conditions } = info;
  return (
    <ParticipantDiv>
      <InitiativeDiv>{initiative}</InitiativeDiv>
      <div>
        <InfoDiv>{name}</InfoDiv>
        <ConditionsDiv>
          {conditions.map((condition) => (
            <Condition
              playerNumber={playerNumber}
              key={condition.conditionNumber}
              removeCondition={removeCondition}
              conditionNumber={condition.conditionNumber}
              conditionName={Conditions5E[condition.conditionNumber].condition}
              conditionColor={
                Conditions5E[condition.conditionNumber].conditionColor
              }
              conditionDuration={condition.conditionDuration}
            />
          ))}
          <AddConditionButton onClick={() => addCondition(playerNumber, 1)}>
            + Condition
          </AddConditionButton>
        </ConditionsDiv>
      </div>
    </ParticipantDiv>
  );
}

Participant.propTypes = {
  playerNumber: PropTypes.number.isRequired,
  addCondition: PropTypes.func.isRequired,
  removeCondition: PropTypes.func.isRequired,
};

Participant.defaultProps = {};

export default Participant;
