const Conditions5E = [
  {
    condition: 'Blind',
    conditionColor: '#000000',
  },
  {
    condition: 'Charmed',
    conditionColor: '#FF00FF',
  },
  {
    condition: 'Deaf',
    conditionColor: '#228B22',
  },
  {
    condition: 'Fatigued',
    conditionColor: '#B22222',
  },
  {
    condition: 'Frightened',
    conditionColor: '#483D8B',
  },
  {
    condition: 'Grappled',
    conditionColor: '#5F9EA0',
  },
  {
    condition: 'Incapacitated',
    conditionColor: '#DC143C',
  },
  {
    condition: 'Invisible',
    conditionColor: '#F5F5F5',
  },
  {
    condition: 'Paralyzed',
    conditionColor: '#AABBCC',
  },
  {
    condition: 'Petrified',
    conditionColor: '#708090',
  },
  {
    condition: 'Poisoned',
    conditionColor: '#6B8E23',
  },
  {
    condition: 'Prone',
    conditionColor: '#9ACD32',
  },
  {
    condition: 'Restrained',
    conditionColor: '#6A5ACD',
  },
  {
    condition: 'Stunned',
    conditionColor: '#C71585',
  },
  {
    condition: 'Unconscious',
    conditionColor: '#F0E68C',
  },
  {
    condition: 'Exhausted',
    conditionColor: '#DAA520',
  },
];

export default Conditions5E;
