import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ConditionDiv = styled.div`
  background-color: ${(props) => props.color || 'transparent'};
  color: white;
  border-radius: 6px;
  margin: 4px;
  padding: 4px;
  display: grid;
  grid-template-columns: 1fr 16px;
`;

const Name = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: 0px;
  color: white;
  display: flex;
  flex: 1;
  padding: 0 4px;
  justify-content: flex-end;
`;

function Condition(props) {
  const {
    playerNumber,
    conditionNumber,
    conditionName,
    conditionDuration,
    conditionColor,
    removeCondition,
  } = props;
  return (
    <ConditionDiv color={conditionColor}>
      <Name>{`${conditionName} - ${conditionDuration}`}</Name>
      <CloseButton
        onClick={() => removeCondition(playerNumber, conditionNumber)}
      >
        X
      </CloseButton>
    </ConditionDiv>
  );
}

Condition.propTypes = {
  playerNumber: PropTypes.number.isRequired,
  conditionNumber: PropTypes.number.isRequired,
  conditionName: PropTypes.string.isRequired,
  conditionDuration: PropTypes.number.isRequired,
  conditionColor: PropTypes.string.isRequired,
  removeCondition: PropTypes.func.isRequired,
};

Condition.defaultProps = {};

export default Condition;
