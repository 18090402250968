const Colors = {
  init: '#F2F2F4',
  blue: '#0000AA',
  black: '#000000',
  red: '#AA0000',
  green: '#007700',
  gray: '#C0C0C0',
};

export default { Colors };
